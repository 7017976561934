<template>
  <b-card
    class="card-app-design rooms-container"
    title="Inventory Details"
  >
    <b-row>
      <b-col sm="12">
        <b-button
          variant="outline-primary"
          @click="handleBackToInventory"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            class="mr-50"
          />
          <span class="align-middle">Back to inventory</span>

        </b-button>
      </b-col>
      <b-col sm="12">
        <b-card
          bg-variant="transparent"
          border-variant="info"
          class="shadow-none mt-3"
        >
          <b-row>
            <b-col sm="6">
              <b>Item: <span class="text-info">{{ selectedItem }}</span></b>
            </b-col>
            <b-col sm="6">
              <b>Available Quantity: <span class="text-info">{{ selectedItemQuantity }}</span></b>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="12">
        <b-card
          border-variant="secondary"
          title="Stock-in"
        >
          <b-row>
            <b-col sm="12">
              <vue-good-table
                :columns="stockInColumns"
                :rows="stockInData"
                :rtl="direction"
                :search-options="{
                  enabled: true,
                  externalQuery: searchInStockTerm
                }"
                :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true,
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true,
                  selectAllByGroup: true,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item @click="handleViewStockinDetailsModal(props.row)">
                          <feather-icon
                            icon="EyeIcon"
                            class="mr-50"
                          />
                          <span>View</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">

                    <!-- page length -->
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        Showing 1 to
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="pages"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>

                    <!-- pagination -->
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col sm="12">
        <b-card
          border-variant="secondary"
          title="Stock-out"
        >
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <vue-good-table
                  :columns="stockOutColumns"
                  :rows="stockOutData"
                  :rtl="direction"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchOutStockTerm
                  }"
                  :select-options="{
                    enabled: false,
                    selectOnCheckboxOnly: true,
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true,
                    selectAllByGroup: true,
                  }"
                  :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                  }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >

                    <!-- Column: Action -->
                    <span v-if="props.column.field === 'action'">
                      <span>
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item @click="handleViewStockOutDetailsModal(props.row)">
                            <feather-icon
                              icon="EyeIcon"
                              class="mr-50"
                            />
                            <span>View</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">

                      <!-- page length -->
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="pages"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>

                      <!-- pagination -->
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
                <b-modal
                  v-model="openStockinDetailsModal"
                  title="Stockin Details"
                  size="lg"
                  ok-only
                >
                  <b-row>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Item:</span><span class="text-info ml-1">{{ stockinFormData.item_name }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Stockin Quantity:</span><span class="text-info ml-1">{{ formatNumber(stockinFormData.quantity) }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Unit:</span><span class="text-info ml-1">{{ stockinFormData.item_unit_name }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Cost Price:</span><span class="text-info ml-1">{{ formatNumber(stockinFormData.total_cost)+'Ugx' }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Stockin Date:</span><span class="text-info ml-1">{{ stockinFormData.stock_in_date }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                    >
                      <p><span>Additional Notes:</span><span class="text-info ml-1">{{ stockinFormData.remarks }}</span></p>
                    </b-col>
                  </b-row>
                </b-modal>
                <b-modal
                  v-model="openStockOutDetailsModal"
                  title="Stockout Details"
                  size="lg"
                  ok-only
                >
                  <b-row>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Item:</span><span class="text-info ml-1">{{ stockoutFormData.stock_item_name }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Quantity:</span><span class="text-info ml-1">{{ stockoutFormData.quantity }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Unit:</span><span class="text-info ml-1">{{ stockoutFormData.item_unit_name }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Stockout Date:</span><span class="text-info ml-1">{{ stockoutFormData.stock_out_date }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <p><span>Recieved By:</span><span class="text-info ml-1">{{ stockoutFormData.recievedby_name }}</span></p>
                    </b-col>
                    <b-col
                      sm="12"
                    >
                      <p><span>Additional Notes:</span><span class="text-info ml-1">{{ stockoutFormData.remark }}</span></p>
                    </b-col>
                  </b-row>
                </b-modal>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BDropdownItem,
  BDropdown, BFormSelect, BPagination, BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { supabase } from '@/libs/supabaseClient'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import { formatNumber } from '@core/utils/utils'

export default {
  components: {
    BModal,
    BCard,
    BButton,
    BRow,
    BCol,
    VueGoodTable,
    BDropdownItem,
    BDropdown,
    BFormSelect,
    BPagination,
  },

  data() {
    return {
      stockItemId: 0,
      pageLength: 3,
      selectedItem: '',
      openStockinDetailsModal: false,
      openStockOutDetailsModal: false,
      selectedItemQuantity: '',
      stockInData: [],
      stockOutData: [],
      formatNumber,
      inventoryData: [],
      inventoryRows: [],
      stockoutFormData: {
        stock_item_id: 0,
        stock_item_name: '',
        item_unit_id: '',
        item_unit_name: '',
        quantity: 0,
        stock_out_date: '',
        stock_out_time: 0,
        recieved_by_id: 0,
        recievedby_name: '',
        remark: '',
      },
      stockinFormData: {
        item_id: 0,
        item_name: '',
        item_unit_id: 0,
        item_unit_name: '',
        quantity: 0,
        total_cost: 0,
        stock_in_date: '',
        stock_in_time: 0,
        remarks: '',
        type: '',
      },
      pages: ['3', '5', '10'],
      searchOutStockTerm: '',
      searchInStockTerm: '',
      stockInColumns: [
        {
          label: 'Date',
          field: 'stock_in_date',
        },
        {
          label: 'Name',
          field: 'item_name',
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
        {
          label: 'Unit',
          field: 'item_unit_name',
        },
        {
          label: 'Amount',
          field(item) {
            return formatNumber(item.total_cost)
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      stockOutColumns: [
        {
          label: 'Date',
          field: 'stock_out_date',
        },
        {
          label: 'Item',
          field: 'stock_item_name',
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
        {
          label: 'Unit',
          field: 'item_unit_name',
        },
        {
          label: 'Recieved By',
          field: 'recievedby_name',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  created() {
    this.handleGetStockinData()
  },

  methods: {
    handleChangePage() {

    },
    handleBackToInventory() {
      this.$router.push({ name: 'stock' })
    },
    handleViewStockinDetailsModal(item) {
      this.openStockinDetailsModal = true
      this.stockinFormData = item
    },
    handleViewStockOutDetailsModal(item) {
      this.openStockOutDetailsModal = true
      this.stockoutFormData = item
    },
    async handleGetStockinData() {
      this.stockItemId = this.$route.query.id
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('stock_in')
          .select(`
            *
          `).eq('item_id', this.stockItemId)
          .order('stock_in_time', { descending: true })

        const { data: stockOutResponse, stockOutError } = await supabase
          .from('stock_out')
          .select(`
            *
          `).eq('stock_item_id', this.stockItemId)
          .order('stock_out_time', { descending: true })
        const { data: inventoryResponse, inventoryError } = await supabase
          .from('inventory')
          .select(`
            *
          `).eq('item_id', this.stockItemId)
        if (error || stockOutError || inventoryError) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          this.inventoryData = inventoryResponse
          this.selectedItem = inventoryResponse[0].item_name
          this.selectedItemQuantity = `${inventoryResponse[0].quantity} ${inventoryResponse[0].unit}`
          if (response.length > 0) {
            this.stockInData = []
            this.stockInData = response
          }

          if (stockOutResponse.length > 0) {
            this.stockOutData = []
            this.stockOutData = stockOutResponse
          }
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
